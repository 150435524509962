import HomeworkStatus from '@web/consts/HomeworkStatus'
import i18n from '@web/plugins/i18n'

/**
 * @function getDayHomeworkStatusID
 * @description Вернет статус домашки
 * @param {Array} dayHomeworks
 * @return {String} Статус
 */
const getDayHomeworkStatusID = (dayHomeworks): string => {
  const isFail = dayHomeworks.some(({ status }) => status === HomeworkStatus.FAIL)
  if (isFail) {
    return HomeworkStatus.FAIL
  }
  const isDone = dayHomeworks.every(({ status }) => status === HomeworkStatus.DONE)
  if (isDone) {
    return HomeworkStatus.DONE
  }
  const isSend = dayHomeworks.every(({ status }) => status === HomeworkStatus.UPLOADED)
  if (isSend) {
    return HomeworkStatus.UPLOADED
  }
  return HomeworkStatus.NOT_UPLOADED
}

/**
 * @function getDayHomeworkStatusText
 * @description Вернет текст статуса домашки
 * @param {Array} dayHomeworks
 * @return {String} Текст статуса
 */
const getDayHomeworkStatusText = (dayHomeworks): string => {
  const homeworkStatus = getDayHomeworkStatusID(dayHomeworks)
  if (homeworkStatus === HomeworkStatus.NOT_UPLOADED) {
    const count = dayHomeworks.length
    return count + ' ' + i18n.global.t(count === 1 ? 'task' : 'tasks').toLowerCase()
  }

  return i18n.global.t(`homeworkStatuses.${homeworkStatus}`)
}

/**
 * @function getHomeworkStatus
 * @description Вернет обьект статуса домашки
 * @return {Object} Обьект статуса
 * @param statuses
 */
export const getHomeworkStatus = (statuses: HomeworkStatus[]): {
  status: string;
  text: string;
} => {
  return statuses && statuses.length > 0
    ? {
        status: getDayHomeworkStatusID(statuses),
        text: getDayHomeworkStatusText(statuses)
      }
    : {
        status: '',
        text: ''
      }
}
