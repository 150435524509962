import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lCommon page-courseFreeze" }
const _hoisted_2 = {
  key: 0,
  class: "lCommon page-courseFreeze_content"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "page-courseFreeze_content"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_freeze_calendar = _resolveComponent("as-freeze-calendar")!
  const _component_as_button = _resolveComponent("as-button")!
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_header = _resolveComponent("as-header")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, { target: "mobile-tablet" }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          title: _ctx.$t('suspendCourse'),
          "prev-page": _ctx.toCalendar,
          "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
        }, null, 8, ["title", "prev-page"]),
        (_ctx.loaded)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_as_freeze_calendar, {
                labels: _ctx.freezeLabels,
                modelValue: _ctx.datesFreezeCourse,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.datesFreezeCourse) = $event)),
                "min-date": _ctx.minDateFreezeCourse,
                class: "page-courseFreeze_calendars",
                disabled: _ctx.isDisabledSelectCalendars
              }, null, 8, ["labels", "modelValue", "min-date", "disabled"]),
              _createElementVNode("div", {
                class: "page-courseFreeze_tip",
                innerHTML: _ctx.calendarsText
              }, null, 8, _hoisted_3),
              _createVNode(_component_as_button, {
                class: _normalizeClass(["page-courseFreeze_btn", {
            '-disabled': _ctx.isDisabledFreeze
          }]),
                onClick: _ctx.onFreezeCourse,
                disabled: _ctx.isDisabledFreeze
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                ]),
                _: 1
              }, 8, ["class", "onClick", "disabled"])
            ], 512)), [
              [_vShow, !_ctx.isOpenMenu]
            ])
          : _withDirectives((_openBlock(), _createBlock(_component_as_preloader, { key: 1 }, null, 512)), [
              [_vShow, !_ctx.isOpenMenu]
            ])
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.title }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, null, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              modelValue: _ctx.menuMini,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.menuMini) = $event)),
              minify: true,
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["modelValue", "menu"])
          ]),
          content: _withCtx(() => [
            _createVNode(_component_as_row, { class: "-inner" }, {
              content: _withCtx(() => [
                (_ctx.course)
                  ? (_openBlock(), _createBlock(_component_as_header, {
                      key: 0,
                      background: _ctx.getBackground('medium'),
                      title: _ctx.course.caption,
                      subtitle: _ctx.course.coachName,
                      back: false,
                      nav: false
                    }, null, 8, ["background", "title", "subtitle"]))
                  : _createCommentVNode("", true),
                (_ctx.loaded)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_as_freeze_calendar, {
                        labels: { start: 'Дата начала', end: 'Дата окончания' },
                        modelValue: _ctx.datesFreezeCourse,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.datesFreezeCourse) = $event)),
                        "min-date": _ctx.minDateFreezeCourse,
                        class: "page-courseFreeze_calendars",
                        disabled: _ctx.isDisabledSelectCalendars
                      }, null, 8, ["modelValue", "min-date", "disabled"]),
                      _createElementVNode("div", {
                        class: "page-courseFreeze_tip",
                        innerHTML: _ctx.calendarsText
                      }, null, 8, _hoisted_5),
                      _createVNode(_component_as_button, {
                        class: _normalizeClass(["page-courseFreeze_btn", {
                    '-disabled': _ctx.isDisabledFreeze
                  }]),
                        onClick: _ctx.onFreezeCourse,
                        disabled: _ctx.isDisabledFreeze
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
                        ]),
                        _: 1
                      }, 8, ["class", "onClick", "disabled"])
                    ]))
                  : (_openBlock(), _createBlock(_component_as_preloader, { key: 2 }))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}