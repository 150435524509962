import { useStore } from '@web/store'
import { isDefined } from 'ts-is-present'
import STAGE_STATUS from '@web/consts/StageStatus'
import DAY_STATUS from '@web/consts/DayStatus'
import { getPrevStage } from '@web/common/Stage'
import { getHomeworkStatus } from '@web/common/HomeworkStatuses'
import { DayStatus, Stream } from '@web/store/types/modules/stream'
import i18n from '@web/plugins/i18n'
import Logger from '@web/common/Logger'
import Time from '@web/common/Time'
import HOMEWORK_STATUS from '@web/consts/HomeworkStatus'

interface HomeworkStage {
  homework: Homework | undefined;
  status: HomeworkStatus | undefined;
  link: { name: string; params: { homeworkId: number } } | undefined;
  isRequired?: boolean;
}

interface HomeworksRequiredBefore {
  homework: Homework | undefined;
  status: HomeworkStatus | undefined;
  link: { name: string; params: { homeworkId: number } } | undefined;
}

const store = useStore()

export const getHomeworks = (status: StageStatus, streamId: number): { homework: Homework | undefined; status: HomeworkStatus | undefined; isRequired: boolean }[] => {
  return status.requestStageHomeworks
    .map(id => {
      let isRequired = true
      let homework: StageHomework | DayHomework | undefined = store.getters['homework/getStageHomeworkById'](id)
      if (!homework) {
        const _h = store.getters['homework/getDayHomeworkById'](id)
        homework = _h
        isRequired = typeof _h?.isRequired === 'boolean' ? _h?.isRequired : true
      } else {
        // TODO Пока уберем эту проверку надо разобратся как реализовать на бэке
        // isRequired = typeof homework?.requireExpertApprove === 'boolean' ? homework?.requireExpertApprove : true
      }
      return {
        homework: homework ? store.getters['homework/getHomeworkById'](homework.homeworkId) : undefined,
        status: store.getters['homework/getHomeworkStatusByTargetIdAndStreamId'](id, streamId),
        isRequired
      }
    })
    .filter(isDefined)
}

export const getHomeworksRequiredBefore = (status: StageStatus, streamId: number): { homework: Homework | undefined; status: HomeworkStatus | undefined }[] => {
  return status.requestStageHomeworks
    .map(id => {
      let isRequired = true
      let homework: StageHomework | DayHomework | undefined = store.getters['homework/getStageHomeworkById'](id)
      if (!homework) {
        const _h = store.getters['homework/getDayHomeworkById'](id)
        homework = _h
        isRequired = typeof _h?.isRequired === 'boolean' ? _h?.isRequired : true
      } else {
        // TODO Пока уберем эту проверку надо разобратся как реализовать на бэке
        // isRequired = typeof homework?.requireExpertApprove === 'boolean' ? homework?.requireExpertApprove : true
      }
      return isRequired
        ? {
            homework: homework ? store.getters['homework/getHomeworkById'](homework.homeworkId) : undefined,
            status: store.getters['homework/getHomeworkStatusByTargetIdAndStreamId'](id, streamId)
          }
        : undefined
    })
    .filter(isDefined)
}

interface DaysStageCalendar {
  homeworks: {
    homework: Homework;
    dayHomework: DayHomework;
    status: HomeworkStatus;
  }[];
  hasHomework: boolean;
  homeworksIsRequired: boolean;
  homeworkStatuses: HomeworkStatus[];
  homeworkStatus: { status: string; text: string };
  link: { name: string; params: { streamId: number; dayNumber: number; dayId: number; courseId: number; stageId: number } } | string;
  isDisabled: boolean;
  isOpen?: boolean;
  openDate?: string;
  isGamificationEnabled: boolean;
  day: Day;
  status: DayStatus | undefined;
}

export function getDays (
  stage: Stage,
  status: StageStatus | undefined,
  stream: Stream,
  daysPrevStage: DaysStageCalendar[]
): DaysStageCalendar[] {
  if (!Array.isArray(stage.dayIds)) return []

  const getDayById = (id: number): Day | undefined => store.getters['day/getDayById'](id)
  const course = store.getters['course/getCourseById'](stream.courseId)
  const isGamificationEnabled = Boolean(course?.gamificationEnabled) && !stream.courseFullAccess

  let prevDay: DaysStageCalendar
  const _prevDay = daysPrevStage
    .sort((prev, next) => prev.day.dayNumber - next.day.dayNumber)[daysPrevStage.length - 1]

  if (_prevDay) {
    prevDay = _prevDay
  }

  return stage.dayIds
    .map(getDayById)
    .filter(isDefined)
    .sort((prev, next) => prev.dayNumber - next.dayNumber)
    .map(day => {
      const homeworks = store.getters['homework/getHomeworksByDayIdAndStreamId'](day.id, stream.id)
      const homeworkStatuses = homeworks.map(({ status }) => status)
      const homeworksIsRequired = homeworks.some(h => h.dayHomework?.isRequired)
      const homeworkStatus = getHomeworkStatus(homeworkStatuses)
      const hasHomework = Array.isArray(homeworkStatuses) && homeworkStatuses.length > 0
      const isDisabled = day.dayOfRest || status?.status === STAGE_STATUS.CLOSE
      let isOpen = false
      let openDate = ''

      if (isGamificationEnabled) {
        if (status?.status === STAGE_STATUS.OPEN) { // Если стадия открыта
          if (prevDay) { // Если есть предыдущий день
            if (prevDay?.isOpen) { // Если предыдущий день открыт
              if (day.doNotBlock) { // Если текущий день надо открыть вместе с предыдущим
                isOpen = true
              } else if (prevDay?.day.dayOfRest) { // Если предыдущий день выходной
                isOpen = Time(prevDay.status?.endTime).isBefore(Time(), 'day')
                if (!isOpen) { // Если предыдущий день выходной и текущий закрыт, выводим дату открытия
                  openDate = Time(prevDay.status?.endTime).add(1, 'day').format('DD.MM.YYYY')
                }
              } else if (prevDay?.status?.status === DAY_STATUS.ENDED) { // Если предыдущий день выполнен
                if (prevDay.hasHomework && prevDay.homeworksIsRequired) { // Если у предыдущего дня есть обязательные домашки
                  const requiredHomeworks = prevDay.homeworks
                    .filter(h => h.dayHomework?.isRequired) // Оставляем только обязательные домашки
                  const homeworksIsDone = requiredHomeworks
                    .every(h => h.status.status === HOMEWORK_STATUS.DONE) // Если у предыдущего дня все обязательные домашки выполнены
                  if (homeworksIsDone) { // Если домашки все выполнены, то проверяем дату последней выполненой домашки
                    isOpen = Time(prevDay.status?.endTime).isBefore(Time(), 'day')
                    // Время рассчитывалось от того когда домашка была выполнена
                    // const prevHomeworksTime = requiredHomeworks.map(h => h.status.updatedAt * 1000).sort()[prevDay.homeworks.length - 1]
                    // isOpen = Time(prevHomeworksTime).isBefore(Time(), 'day')
                    // if (!isOpen) {
                    //   openDate = Time(prevHomeworksTime).add(1, 'day').format('DD.MM.YYYY')
                    // }
                  }
                } else {
                  isOpen = Time(prevDay.status?.endTime).isBefore(Time(), 'day')
                }

                if (!isOpen) {
                  openDate = Time(prevDay.status?.endTime).add(1, 'day').format('DD.MM.YYYY')
                }

                if (!isOpen && prevDay?.day.dayOfRest) { // Если предыдущий день выходной и текущей день закрыт
                  // isOpen = true
                  openDate = Time(prevDay.status?.endTime).add(1, 'day').format('DD.MM.YYYY')
                }
              } else {
                isOpen = false
              }
            } else {
              isOpen = false
            }
          } else {
            isOpen = true
          }
        } else {
          isOpen = false
        }
      } else {
        isOpen = true
      }

      const path = {
        name: 'day',
        params: {
          courseId: stream.courseId,
          dayNumber: day.dayNumber,
          streamId: stream.id,
          stageId: stage.id,
          dayId: day.id
        }
      }
      const link = !isDisabled ? path : ''

      const currentDayStatus = store.getters['stream/getDayStatus']({ streamId: stream.id, dayId: day.id })

      if (currentDayStatus && day.dayOfRest && prevDay) {
        currentDayStatus.endTime = Time(prevDay.status?.endTime).add(1, 'day').unix() * 1000
      }

      prevDay = {
        homeworks,
        status: currentDayStatus,
        day,
        isDisabled,
        isOpen,
        openDate,
        homeworkStatuses,
        hasHomework,
        homeworksIsRequired,
        homeworkStatus,
        link,
        isGamificationEnabled
      }

      return prevDay
    })
}

export const isShowWarningBefore = (stage: Stage, stream: Stream): boolean => {
  if (stream.courseFullAccess) {
    return false
  }

  const prevStage = getPrevStage(stage.id, stream.courseId)
  if (prevStage && store.getters['stream/getStageStatus']({ streamId: stream.id, stageId: prevStage.id })?.status === STAGE_STATUS.CLOSE) {
    return false
  }

  const status = store.getters['stream/getStageStatus']({ streamId: stream.id, stageId: stage.id })
  return status ? getHomeworksRequiredBefore(status, stream.id).length > 0 : false
}

export const getTitleDaysRequiredToComplete = (stage: StageStatus): string => {
  const dayTitles: string[] = stage.requestDays
    .map(dayId => store.getters['day/getDayById'](dayId)?.title || `${i18n.global.t('Day')} ${dayId}`)

  const homeworkTitles: string[] = stage.requestHomeworks
    .map(id => {
      const homework = store.getters['homework/getStageHomeworkById'](id) || store.getters['homework/getDayHomeworkById'](id)
      return homework ? store.getters['homework/getHomeworkById'](homework.homeworkId)?.title : `${i18n.global.t('task')} ${id}`
    })
    .filter(isDefined)

  return dayTitles.concat(homeworkTitles).join(', ')
}

export const getStreamStages = (stream: Stream): {
  stage: Stage;
  status: StageStatus;
  number: number;
  days: {
    hasHomework: boolean;
    homeworksIsRequired: boolean;
    homeworkStatuses: HomeworkStatus[];
    homeworkStatus: { status: string; text: string };
    link: { name: string; params: { streamId: number; dayNumber: number; dayId: number; courseId: number; stageId: number } } | string;
    isDisabled: boolean;
    isOpen?: boolean;
    openDate?: string;
    day: Day;
    status: DayStatus | undefined;
  }[];
  isShowWarningBefore: boolean;
  isRequestToComplete: boolean;
  homeworksRequiredBefore: HomeworksRequiredBefore[];
  homeworks: HomeworkStage[];
  warning: string;
  titleDaysRequiredToComplete: string;
  percent: number;
}[] => {
  const course: Course | undefined = store.getters['course/getCourseById'](stream.courseId)

  if (!course) {
    Logger.warn('Not found course')
    return []
  }

  const stageIds: number[] = store.getters['course/getCourseStageIds'](course.id)
  if (stageIds.length === 0) {
    Logger.warn('Not found stages in course')
    return []
  }

  if (!store.getters['stream/getStageStatus']({ streamId: stream.id, stageId: stageIds[0] })) {
    Logger.warn('Not found stages status')
    return []
  }

  function getHomeworkLink (id: number) {
    return {
      name: 'homework',
      params: {
        homeworkId: id
      }
    }
  }
  function requestToComplete (status: StageStatus) {
    if (status.status === STAGE_STATUS.CLOSE) {
      return false
    }
    return status.requestDays.length > 0 || status.requestHomeworks.length > 0
  }

  const getStageById = (id: number): Stage | undefined => store.getters['stage/getStageById'](id)
  const stages = stageIds
    .map(getStageById)
    .filter(isDefined)
    .filter(stage => stage.dayIds.length > 0)
    .sort((a, b) => a.sort - b.sort)

  let daysPrevStage: DaysStageCalendar[] = []

  return stages
    .map((stage, i) => {
      const status = store.getters['stream/getStageStatus']({ streamId: stream.id, stageId: stage.id })
      if (!status) {
        return undefined
      }
      let homeworksRequiredBefore: HomeworksRequiredBefore[] = []
      if (status) {
        homeworksRequiredBefore = getHomeworksRequiredBefore(status, stream.id)
          .map((h): HomeworksRequiredBefore => ({
            ...h,
            link: h.status ? getHomeworkLink(h.status.id) : undefined
          }))
      }

      let homeworks: HomeworkStage[] = []
      if (status) {
        homeworks = getHomeworks(status, stream.id)
          .map((h): HomeworkStage => ({
            ...h,
            link: h.status ? getHomeworkLink(h.status.id) : undefined
          }))
      }

      let isRequestToComplete = false
      let titleDaysRequiredToComplete = ''

      if (status) {
        isRequestToComplete = requestToComplete(status)
        titleDaysRequiredToComplete = getTitleDaysRequiredToComplete(status)
      }

      daysPrevStage = getDays(stage, status, stream, daysPrevStage)

      return {
        stage,
        status: status || { status: STAGE_STATUS.OPEN },
        number: i + 1,
        days: daysPrevStage,
        isShowWarningBefore: stream.courseFullAccess ? false : isShowWarningBefore(stage, stream),
        isRequestToComplete: stream.courseFullAccess ? false : isRequestToComplete,
        homeworksRequiredBefore,
        homeworks,
        warning: '',
        titleDaysRequiredToComplete,
        percent: status?.progress.progress || 0
      }
    })
    .filter(isDefined)
    .map((stage, i, stages) => {
      if (i > 0) {
        const prevStage = stages[i - 1]
        if (prevStage.status && prevStage.status?.status !== STAGE_STATUS.CLOSE) {
          stage.warning = stream.courseFullAccess ? '' : prevStage.titleDaysRequiredToComplete
        }
      }

      return stage
    })
}
