
import { defineComponent, ref, onMounted, computed } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsHeader from '@web/components/headers/AsHeader.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsFreezeCalendar from '@web/components/AsFreezeCalendar.vue'
import AsPreloader from '@web/components/AsPreloader.vue'

import i18n from '@web/plugins/i18n'
import Logger from '@web/common/Logger'
import Analytics from '@web/services/Analytics/Analytics'

import { performanceEnd } from '@web/common/Utils'

import useCalendar from '@web/composition/useCalendar'

import { useRouter } from 'vue-router'
import { useStore } from '@web/store'
import FirebasePerformance from '@web/services/firebasePerformance'
import { useMeta } from '@web/common/vueMeta'
import OneSignal from '@web/common/OneSignal'
import AsButton from '@web/components/AsButton.vue'

import Time from '@web/common/Time'
import { USER_COURSE_STREAM_FREEZE_STATUS } from '@web/consts/StreamStatus'

export default defineComponent({
  name: 'CourseFreeze',
  setup () {
    const store = useStore()
    const router = useRouter()

    const title = ref(i18n.global.t('freezeCourse'))
    const menuMini = ref(false)
    const isFreezing = ref(false)
    const isOpenMenu = ref(false)

    const freezeLabels = ref({
      start: i18n.global.t('startDate'),
      end: i18n.global.t('endDate')
    })

    useMeta({
      title
    })

    const {
      loaded,
      menu,
      // checkId,
      course,
      stages,
      fetchData,
      stream,
      setPageData
    } = useCalendar({ router })

    function getBackground (size) {
      return course.value?.background[size]
    }

    const datesFreezeCourse = ref<{
      start?: string;
      end?: string;
    }>({})
    const minDateFreezeCourse = ref<string | boolean>(Time().add(1, 'day').format('YYYY-MM-DD'))
    const inputDateStyle = {
      dateStyle: 'short'
    }

    const isDisabledFreeze = computed(() => (!datesFreezeCourse.value.start && !datesFreezeCourse.value.end) || isFreezing.value)
    const isDisabledSelectCalendars = computed(() => {
      return [USER_COURSE_STREAM_FREEZE_STATUS.created, USER_COURSE_STREAM_FREEZE_STATUS.active]
        .includes(stream.value?.userCourseStreamFreeze?.status?.toString() || '')
    })
    const calendarsText = computed(() => {
      if (!isDisabledSelectCalendars.value) {
        return i18n.global.t('periodCourse', { name: course.value ? `"${course.value.caption}"` : '' })
      }
      return i18n.global.t('setPeriodCourse', {
        name: course.value?.caption || '',
        startDate: Time(datesFreezeCourse.value.start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        endDate: Time(datesFreezeCourse.value.end, 'YYYY-MM-DD').format('DD.MM.YYYY')
      })
    })
    const buttonText = computed(() => isDisabledSelectCalendars.value ? i18n.global.t('Cancel') : i18n.global.t('suspend'))

    const toCalendar = computed(() => {
      if (stream.value) {
        return {
          name: 'calendar',
          params: {
            streamId: stream.value.id
          }
        }
      }

      return { name: 'courses' }
    })

    async function init () {
      // Определяем, является ли поток по подписке и если да, то отправляем на страницу календаря
      if (stream.value?.paymentSubscriptionId) {
        return router.push({
          name: 'calendar',
          params: {
            courseId: String(stream.value.courseId),
            streamId: String(stream.value.id)
          }
        })
      }
      let freezePageLoading = performance.now()
      const startLoading = Date.now()
      try {
        await fetchData()
        if (stream.value && stream.value?.userCourseStreamFreeze) {
          if (['Created', 'Active'].includes(stream.value?.userCourseStreamFreeze?.status?.toString() || '')) {
            datesFreezeCourse.value = {
              start: stream.value?.userCourseStreamFreeze?.startDate,
              end: stream.value?.userCourseStreamFreeze?.endDate
            }
          }
        }
      } catch (error) {
        Logger.error('Freeze page error', error)
      } finally {
        freezePageLoading = performanceEnd(freezePageLoading)
        Logger.info(`Freeze page loaded: ${freezePageLoading} ms`)
        loaded.value = true
        if (stream.value && course.value) {
          setPageData({
            userCourseStreamId: stream.value.id,
            streamId: stream.value.streamId,
            courseId: course.value.id,
            courseTitle: course.value.title,
            startDate: stream.value.startDate,
            endDate: stream.value.endDate
          })

          FirebasePerformance.record({
            traceName: 'load_freezePage',
            startTime: startLoading,
            options: {
              attributes: {
                userCourseStreamId: stream.value.id.toString(),
                streamId: (stream.value.streamId || '').toString(),
                courseId: (course.value?.id || '').toString(),
                courseTitle: course.value?.title || ''
              }
            }
          })

          if (course.value.categoryId) {
            OneSignal.sendTag(`course_category_${course.value.categoryId}`, '1')
          }
        }

        Analytics.send({
          category: 'ux.performance',
          action: 'load_freezePage',
          label: freezePageLoading.toString()
        })

        if (course.value) {
          const user = store.getters['user/user']
          Analytics.send({
            category: 'ux.training',
            action: 'open_freezePage',
            label: `${user.id}:${course.value.id}`
          })
        }
      }
    }

    async function onFreezeCourse () {
      if (stream.value && !isDisabledFreeze.value) {
        isFreezing.value = true
        if (isDisabledSelectCalendars.value) {
          try {
            store.dispatch('stream/unfreeze', {
              streamId: stream.value.id
            })
          } catch (e) {
            Logger.error(`Error unfreezing userCourseStream: id = ${stream.value.id}`, e)
          } finally {
            isFreezing.value = false
          }
        } else {
          if (datesFreezeCourse.value.start && datesFreezeCourse.value.end) {
            try {
              await store.dispatch('stream/freeze', {
                streamId: stream.value.id,
                startDate: datesFreezeCourse.value.start,
                endDate: datesFreezeCourse.value.end
              })
            } catch (e) {
              Logger.error(`Error freezing userCourseStream: id = ${stream.value.id}`, e)
            } finally {
              isFreezing.value = false
            }
          }
        }
      }
    }

    onMounted(() => {
      init()
    })

    return {
      freezeLabels,
      loaded,
      title,
      menuMini,
      isFreezing,
      isOpenMenu,
      menu,
      course,
      stream,
      stages,
      toCalendar,

      datesFreezeCourse,
      minDateFreezeCourse,
      inputDateStyle,
      isDisabledFreeze,
      isDisabledSelectCalendars,
      calendarsText,
      buttonText,

      // Methods
      getBackground,
      onFreezeCourse
    }
  },
  components: {
    AsButton,
    MqResponsive,
    AsHeaderMobile,
    AsHeader,
    AsRow,
    AsHeaderMain,
    AsMenu,
    AsSpacer,
    AsFooter,
    AsBreadcrumb,
    AsFreezeCalendar,
    AsPreloader
  }
})
