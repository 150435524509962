import { useStore } from '@web/store'
import { isDefined } from 'ts-is-present'

export const getPrevStage = (stageId: number, courseId: number): Stage | void => {
  const store = useStore()
  const course: Course | undefined = store.getters['course/getCourseById'](courseId)
  if (course) {
    const getStageById: (id: number) => Stage | undefined = store.getters['stage/getStageById']
    const stageIds: number[] = store.getters['course/getCourseStageIds'](course.id)
    const stages: Stage[] = stageIds
      .map(getStageById)
      .filter(isDefined)

    if (stages.length > 0) {
      const index = stages.findIndex(({ id }) => id === stageId)
      if (index > 0) {
        return stages[index - 1]
      }
    }
  }
}
