
import { computed, defineComponent, ref, PropType } from 'vue'

import MazPicker from 'maz-ui/components/MazPicker'

import AsButton from '@web/components/AsButton.vue'
import AsModal from '@web/components/AsModal.vue'

import Time, { dayjs } from '@web/common/Time'

interface RangeDate {
  start: string;
  end: string;
}

export default defineComponent({
  name: 'AsFreezeCalendar',
  components: {
    AsButton,
    MazPicker,
    AsModal
  },
  props: {
    modelValue: {
      type: Object as PropType<RangeDate>,
      default: () => ({}) // format: YYYY-MM-DD
    },
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    },
    labels: {
      type: Object,
      default: () => ({ start: '', end: '' })
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxDays: {
      type: Number,
      default: 90
    }
  },
  emits: ['update:modelValue'],
  setup (props, ctx) {
    const showModal = ref(false)
    const selectedDateType = ref<'start' | 'end' | null>(null)
    const inputDateStyle = {
      dateStyle: 'short'
    }
    const currentValue = ref<RangeDate>(props.modelValue)

    const formattedDate = computed<{ start: string, end: string }>(() => {
      return {
        start: Time(currentValue.value.start, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        end: Time(currentValue.value.end, 'YYYY-MM-DD').format('DD.MM.YYYY')
      }
    })

    const maxDateRange = computed(() => {
      let _mDateByDays = Time().add(90, 'day')

      if (currentValue.value.start) {
        _mDateByDays = Time(currentValue.value.start, 'YYYY-MM-DD').add(90, 'day')
      }
      const _maxDateProps = props.maxDate ? Time(props.maxDate, 'YYYY-MM-DD') : _mDateByDays
      return dayjs.min([_maxDateProps, _mDateByDays]).format('YYYY-MM-DD')
    })

    const isDisabledClose = computed(() => !currentValue.value.start || !currentValue.value.end)

    function onShowModal (type: 'start' | 'end') {
      if (!props.disabled) {
        showModal.value = true
        selectedDateType.value = type
      }
    }

    function onCloseModal () {
      if (!isDisabledClose.value) {
        ctx.emit('update:modelValue', currentValue.value)
        showModal.value = false
      }
    }

    function onSelect (payload: RangeDate) {
      currentValue.value = payload
    }

    return {
      currentValue,
      showModal,
      inputDateStyle,
      formattedDate,
      isDisabledClose,
      maxDateRange,
      onShowModal,
      onCloseModal,
      onSelect
    }
  }
})
