import { ref, computed, onBeforeUnmount } from 'vue'
import STREAM_STATUS from '@web/consts/StreamStatus'
import { Stream } from '@web/store/types/modules/stream'
import { useStore } from '@web/store'
import { getStreamStages } from '@web/common/Stream'
import Analytics from '@web/services/Analytics/Analytics'
import { Router } from 'vue-router'
import { useCalendarBanners } from '@web/composition/useBanners'
import LocalStorage from '@web/common/LocalStorage'

const store = useStore()

function useStream (router: Router) {
  const streamId = Number(router.currentRoute.value.params.streamId)
  const stream = computed<Stream | undefined>(() => store.getters['stream/getStreamById'](streamId))
  const course = computed<Course | undefined>(() => {
    return stream.value ? store.getters['course/getCourseById'](stream.value.courseId) : undefined
  })

  const stages = computed(() => {
    if (stream.value) {
      return getStreamStages(stream.value)
    } else {
      return []
    }
  })

  async function fetchData () {
    const stream = await store.dispatch('stream/fetchUserCourseStreamCached', { id: streamId })

    if (stream.status !== STREAM_STATUS.OPEN) {
      router.push({ name: 'courses' })
      return
    }

    await store.dispatch('stage/fetchStagesCached', { courseId: stream.courseId })
    await store.dispatch('stream/fetchStatuses', { id: stream.id, expand: 'stages,days,homeworks' })
  }

  async function fetchDataForce () {
    const stream = await store.dispatch('stream/fetchUserCourseStream', { id: streamId })

    if (stream.status === STREAM_STATUS.ENDED || stream.status === STREAM_STATUS.STOPPED) {
      router.push({ name: 'courses' })
      return
    }

    await store.dispatch('course/fetchCourse', { id: stream.courseId, requestLessons: true })
    await store.dispatch('stage/fetchStages', { courseId: stream.courseId })
    await store.dispatch('stream/fetchStatuses', { id: stream.id, expand: 'stages,days,homeworks' })
  }

  function checkId () {
    if (!store.getters['stream/getStreamById']) {
      router.push({ name: 'courses' })
    }
  }

  return {
    stream,
    course,
    stages,
    fetchData,
    fetchDataForce,
    checkId
  }
}

function useCalendar ({ router }: { router: Router }) {
  const loaded = ref(false)

  const {
    stream,
    course,
    stages,
    fetchData,
    fetchDataForce,
    checkId
  } = useStream(router)

  const menu = computed(() => store.getters['menu/general'])

  function setPageData (payload: {
    userCourseStreamId: number;
    streamId: number;
    courseId: number;
    courseTitle: string;
    startDate: number;
    endDate: number;
  }) {
    Analytics.setPageData({
      userCourseStreamId: payload.userCourseStreamId.toString(),
      streamId: payload.streamId.toString(),
      startDate: payload.startDate.toString(),
      endDate: payload.endDate.toString(),
      courseId: payload.courseId.toString(),
      courseTitle: payload.courseTitle
    })
  }

  const CONTINUE_TRAINING_KEY = 'acProject_continue_training'
  function saveLocationToContinue () {
    let route: unknown = {
      name: 'home-courses'
    }

    if (stream.value) {
      route = {
        name: 'calendar',
        params: {
          streamId: stream.value.id.toString()
        }
      }
    }

    if (route) {
      LocalStorage.set(CONTINUE_TRAINING_KEY, JSON.stringify(route))
    }
  }

  onBeforeUnmount(() => {
    Analytics.clearPageData()
  })

  return {
    loaded,
    menu,
    course,
    stages,
    fetchDataForce,
    checkId,
    fetchData,
    stream,
    setPageData,
    saveLocationToContinue,
    ...useCalendarBanners({ stream })
  }
}

export default useCalendar
